@import "vendor/smart-grid";
@import "parts/fonts";

.page-template-service {
	> .row {
		margin-left: 0;
		margin-right: 0;
	}

	.container {
		width: 100%;
		max-width: 1200px;
	}

	.page {
		overflow-x: hidden;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.sub_menu {
		margin-right: 20px;
		padding-right: 0;
		flex: 0 0 278px;
		display: block !important;

		@include to(1000px) {
			display: none !important;
		}
	}

	.right {
		flex: 1 1 auto;
	}


}

.header-spacer {
	height: 154px;
	@include md-block {
		height: 100px;
	}
	@include sm-block {
		height: 80px;
	}
	@include xs-block {
		height: 72px;
	}
}

.section-title {
	* {
		font-weight: 700;
		font-size: 28px !important;
		line-height: 32px !important;
		color: #000000;
		margin: 0;
		@include sm-block {
			font-size: 26px !important;
			line-height: 130% !important;
		}
		@include xs-block {
			font-size: 24px !important;
		}
	}
}

.section-wysiwig {
	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;

		color: #343434;

		margin-top: 0;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		padding: 0;
		margin-bottom: 12px;
		margin-top: 0;
		list-style: none;
		margin-left: 0;

		li {
			font-weight: 400;
			font-size: 14px;
			line-height: 180%;
			color: #343434;
			position: relative;
			padding-left: 38px;
			@include sm-block {
				line-height: 130%;
				padding-left: 20px;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 25px;
				height: 2px;
				background: #BE0104;
				left: 0;
				top: 0.65em;
				@include sm-block {
					width: 15px;
				}
			}
		}
	}

	ol {
		padding: 0;
		margin-left: 0;
		list-style-type: none;
		counter-reset: num;

		li {

			padding-left: 37px;
			position: relative;
			font-weight: 400;
			font-size: 14px;
			line-height: 160%;
			color: #343434;

			&:not(:last-of-type) {
				margin-bottom: 2px;
			}

			&::before {
				content: counter(num);
				counter-increment: num;
				position: absolute;
				left: 0;
				top: 0.2em;
				width: 20px;
				height: 20px;
				background: #BE0104;
				line-height: 20px;
				text-align: center;
				font-family: 'Gilroy';
				font-style: normal;
				font-weight: 700;
				font-size: 14px;

				color: #FFFFFF;
			}
		}
	}

	* {

		&:last-child {
			margin-bottom: 0;
		}
	}
}

#wpadminbar {
	//display: none;
}

html, body {
	min-height: 100vh !important;
}

body {
	&.noscroll {
		overflow-y: hidden;
	}
}

.constructor {
	> * {
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}

body.compensate-for-scrollbar {
	margin-right: 0 !important;
}

.b24-widget-button-position-bottom-right {
	z-index: 999 !important;
	@include xs-block {
		right: 15px !important;
		bottom: 70px !important;
	}
}

.b24-widget-button-shadow {
	z-index: 998 !important;
}


.site-footer {
	.header-new-top__messengers-label {
		text-align: center;
		color: #fff;
	}

	.header-new-top__messengers-list {
		display: flex;
		justify-content: center;

		img {
			width: 45px !important;
		}
	}
}

.wr-cookie-cofeaokie-bar {
	display: none !important;
}

.red-btn {
	text-align: center;
	display: inline-block;
	transition: all 0.3s;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	color: #FFFFFF;
	padding: 10px 66px;
	background: #BE0104;
	border-radius: 4px;
	text-decoration: none;

	@include xs-block {
		padding-left: 30px;
		padding-right: 30px;

	}

	&:hover {
		opacity: 0.8;
		color: #FFFFFF !important;
		text-decoration: none !important;
	}

	&:active {
		opacity: 0.6;
		color: #FFFFFF !important;
	}

	&:focus {

		color: #FFFFFF !important;
		text-decoration: none !important;
	}
}
.wr-cookie-cookie-bar {
	display: none !important;
}