@use "sass:math";

$columns: 12;
$atom: math.div(100%, $columns);

$break_xlg: 1440px;
$break_lg: 1100px;
$break_w1000: 1000px;
$break_md: 960px;
$break_w900: 900px;
$break_w800: 800px;
$break_sm: 780px;
$break_w700: 700px;
$break_w600: 600px;
$break_xs: 560px;
$break_w500: 500px;
$break_w400: 400px;


$offset: 30px;
$offset_xlg: $offset;
$offset_lg: $offset;
$offset_w1000: $offset;
$offset_md: $offset;
$offset_w900: $offset;
$offset_w800: $offset;
$offset_sm: $offset;
$offset_w700: $offset;
$offset_w600: $offset;
$offset_xs: $offset;
$offset_w500: $offset;
$offset_w400: $offset;

$offset_one_side: ($offset * 0.5);
$offset_xlg_one_side: ($offset_xlg * 0.5);
$offset_lg_one_side: ($offset_lg * 0.5);
$offset_w1000_one_side: ($offset_w1000 * 0.5);
$offset_md_one_side: ($offset_md * 0.5);
$offset_w900_one_side: ($offset_w900 * 0.5);
$offset_w800_one_side: ($offset_w800 * 0.5);
$offset_sm_one_side: ($offset_sm * 0.5);
$offset_w700_one_side: ($offset_w700 * 0.5);
$offset_w600_one_side: ($offset_w600 * 0.5);
$offset_xs_one_side: ($offset_xs * 0.5);
$offset_w500_one_side: ($offset_w500 * 0.5);
$offset_w400_one_side: ($offset_w400 * 0.5);

$fields: 30px;

@mixin wrapper-full(){
    padding-left: $fields;
    padding-right: $fields;
}

@mixin row-offsets(){
    margin-left: ($offset_one_side * -1);
    margin-right: ($offset_one_side * -1);
}

@mixin col-offsets($type){
    #{$type}-left: $offset_one_side;
    #{$type}-right: $offset_one_side;
}

@mixin wrapper(){
    max-width: 1230px;
    margin: 0 auto;
    @include wrapper-full();
}

@mixin row-flex(){
    display: flex;
    flex-wrap: wrap;
    @include row-offsets();
}

@mixin row-float(){
    @include row-offsets();
    @include clearfix();
}

@mixin row-ib(){
    @include row-offsets();
}

@mixin col(){
    box-sizing: border-box;
    word-wrap: break-word;
    @include col-offsets(margin);
}

@mixin col-float(){
    float: left;
}

@mixin col-ib(){
    display: inline-block;
    vertical-align: top;
}

@mixin col-padding(){
    word-wrap: break-word;
    @include col-offsets(padding);
}

@mixin size($n){
    $val: math.div(100%, $columns) * $n;
    width: #{calc(#{$val} - #{$offset})};
}

@mixin size-xlg($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(width, #{calc(#{$val} - #{$offset_xlg})});
}

@mixin size-lg($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(width, #{calc(#{$val} - #{$offset_lg})});
}

@mixin size-w1000($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(width, #{calc(#{$val} - #{$offset_w1000})});
}

@mixin size-md($n){
    $val: math.div(100%, $columns) * $n;
    @include md(width, #{calc(#{$val} - #{$offset_md})});
}

@mixin size-w900($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(width, #{calc(#{$val} - #{$offset_w900})});
}

@mixin size-w800($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(width, #{calc(#{$val} - #{$offset_w800})});
}

@mixin size-sm($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(width, #{calc(#{$val} - #{$offset_sm})});
}

@mixin size-w700($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(width, #{calc(#{$val} - #{$offset_w700})});
}

@mixin size-w600($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(width, #{calc(#{$val} - #{$offset_w600})});
}

@mixin size-xs($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(width, #{calc(#{$val} - #{$offset_xs})});
}

@mixin size-w500($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(width, #{calc(#{$val} - #{$offset_w500})});
}

@mixin size-w400($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(width, #{calc(#{$val} - #{$offset_w400})});
}

@mixin shift($n){
    $val: math.div(100%, $columns) * $n;
    margin-left: #{calc(#{$val} + #{$offset_one_side})};
    margin-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-left($n){
    $val: math.div(100%, $columns) * $n;
    margin-left: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-right($n){
    $val: math.div(100%, $columns) * $n;
    margin-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding($n){
    $val: math.div(100%, $columns) * $n;
    padding-left: #{calc(#{$val} + #{$offset_one_side})};
    padding-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    padding-left: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    padding-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-xlg($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(margin-left, #{calc(#{$val} + #{$offset_xlg_one_side})});
    @include xlg(margin-right, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-xlg-left($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(margin-left, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-xlg-right($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(margin-right, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-xlg-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(padding-left, #{calc(#{$val} + #{$offset_xlg_one_side})});
    @include xlg(padding-right, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-xlg-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(padding-left, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-xlg-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include xlg(padding-right, #{calc(#{$val} + #{$offset_xlg_one_side})});
}

@mixin shift-lg($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(margin-left, #{calc(#{$val} + #{$offset_lg_one_side})});
    @include lg(margin-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-left($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(margin-left, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-right($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(margin-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(padding-left, #{calc(#{$val} + #{$offset_lg_one_side})});
    @include lg(padding-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(padding-left, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include lg(padding-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-w1000($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(margin-left, #{calc(#{$val} + #{$offset_w1000_one_side})});
    @include w1000(margin-right, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-w1000-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(margin-left, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-w1000-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(margin-right, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-w1000-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(padding-left, #{calc(#{$val} + #{$offset_w1000_one_side})});
    @include w1000(padding-right, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-w1000-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(padding-left, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-w1000-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w1000(padding-right, #{calc(#{$val} + #{$offset_w1000_one_side})});
}

@mixin shift-md($n){
    $val: math.div(100%, $columns) * $n;
    @include md(margin-left, #{calc(#{$val} + #{$offset_md_one_side})});
    @include md(margin-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-left($n){
    $val: math.div(100%, $columns) * $n;
    @include md(margin-left, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-right($n){
    $val: math.div(100%, $columns) * $n;
    @include md(margin-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include md(padding-left, #{calc(#{$val} + #{$offset_md_one_side})});
    @include md(padding-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include md(padding-left, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include md(padding-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-w900($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(margin-left, #{calc(#{$val} + #{$offset_w900_one_side})});
    @include w900(margin-right, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w900-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(margin-left, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w900-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(margin-right, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w900-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(padding-left, #{calc(#{$val} + #{$offset_w900_one_side})});
    @include w900(padding-right, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w900-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(padding-left, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w900-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w900(padding-right, #{calc(#{$val} + #{$offset_w900_one_side})});
}

@mixin shift-w800($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(margin-left, #{calc(#{$val} + #{$offset_w800_one_side})});
    @include w800(margin-right, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-w800-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(margin-left, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-w800-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(margin-right, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-w800-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(padding-left, #{calc(#{$val} + #{$offset_w800_one_side})});
    @include w800(padding-right, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-w800-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(padding-left, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-w800-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w800(padding-right, #{calc(#{$val} + #{$offset_w800_one_side})});
}

@mixin shift-sm($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(margin-left, #{calc(#{$val} + #{$offset_sm_one_side})});
    @include sm(margin-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-left($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(margin-left, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-right($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(margin-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(padding-left, #{calc(#{$val} + #{$offset_sm_one_side})});
    @include sm(padding-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(padding-left, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include sm(padding-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-w700($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(margin-left, #{calc(#{$val} + #{$offset_w700_one_side})});
    @include w700(margin-right, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w700-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(margin-left, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w700-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(margin-right, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w700-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(padding-left, #{calc(#{$val} + #{$offset_w700_one_side})});
    @include w700(padding-right, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w700-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(padding-left, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w700-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w700(padding-right, #{calc(#{$val} + #{$offset_w700_one_side})});
}

@mixin shift-w600($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(margin-left, #{calc(#{$val} + #{$offset_w600_one_side})});
    @include w600(margin-right, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-w600-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(margin-left, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-w600-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(margin-right, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-w600-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(padding-left, #{calc(#{$val} + #{$offset_w600_one_side})});
    @include w600(padding-right, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-w600-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(padding-left, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-w600-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w600(padding-right, #{calc(#{$val} + #{$offset_w600_one_side})});
}

@mixin shift-xs($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(margin-left, #{calc(#{$val} + #{$offset_xs_one_side})});
    @include xs(margin-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-left($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(margin-left, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-right($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(margin-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(padding-left, #{calc(#{$val} + #{$offset_xs_one_side})});
    @include xs(padding-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(padding-left, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include xs(padding-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-w500($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(margin-left, #{calc(#{$val} + #{$offset_w500_one_side})});
    @include w500(margin-right, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w500-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(margin-left, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w500-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(margin-right, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w500-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(padding-left, #{calc(#{$val} + #{$offset_w500_one_side})});
    @include w500(padding-right, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w500-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(padding-left, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w500-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w500(padding-right, #{calc(#{$val} + #{$offset_w500_one_side})});
}

@mixin shift-w400($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(margin-left, #{calc(#{$val} + #{$offset_w400_one_side})});
    @include w400(margin-right, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin shift-w400-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(margin-left, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin shift-w400-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(margin-right, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin shift-w400-padding($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(padding-left, #{calc(#{$val} + #{$offset_w400_one_side})});
    @include w400(padding-right, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin shift-w400-padding-left($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(padding-left, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin shift-w400-padding-right($n){
    $val: math.div(100%, $columns) * $n;
    @include w400(padding-right, #{calc(#{$val} + #{$offset_w400_one_side})});
}

@mixin xlg($name, $value){
    @media screen and (max-width: $break_xlg){
        #{$name}: $value;
    }
}

@mixin xlg-block(){
    @media screen and (max-width: $break_xlg){
        @content;
    }
}

@mixin lg($name, $value){
    @media screen and (max-width: $break_lg){
        #{$name}: $value;
    }
}

@mixin lg-block(){
    @media screen and (max-width: $break_lg){
        @content;
    }
}

@mixin w1000($name, $value){
    @media screen and (max-width: $break_w1000){
        #{$name}: $value;
    }
}

@mixin w1000-block(){
    @media screen and (max-width: $break_w1000){
        @content;
    }
}

@mixin md($name, $value){
    @media screen and (max-width: $break_md){
        #{$name}: $value;
    }
}

@mixin md-block(){
    @media screen and (max-width: $break_md){
        @content;
    }
}

@mixin w900($name, $value){
    @media screen and (max-width: $break_w900){
        #{$name}: $value;
    }
}

@mixin w900-block(){
    @media screen and (max-width: $break_w900){
        @content;
    }
}

@mixin w800($name, $value){
    @media screen and (max-width: $break_w800){
        #{$name}: $value;
    }
}

@mixin w800-block(){
    @media screen and (max-width: $break_w800){
        @content;
    }
}

@mixin sm($name, $value){
    @media screen and (max-width: $break_sm){
        #{$name}: $value;
    }
}

@mixin sm-block(){
    @media screen and (max-width: $break_sm){
        @content;
    }
}

@mixin w700($name, $value){
    @media screen and (max-width: $break_w700){
        #{$name}: $value;
    }
}

@mixin w700-block(){
    @media screen and (max-width: $break_w700){
        @content;
    }
}

@mixin w600($name, $value){
    @media screen and (max-width: $break_w600){
        #{$name}: $value;
    }
}

@mixin w600-block(){
    @media screen and (max-width: $break_w600){
        @content;
    }
}

@mixin xs($name, $value){
    @media screen and (max-width: $break_xs){
        #{$name}: $value;
    }
}

@mixin xs-block(){
    @media screen and (max-width: $break_xs){
        @content;
    }
}

@mixin w500($name, $value){
    @media screen and (max-width: $break_w500){
        #{$name}: $value;
    }
}

@mixin w500-block(){
    @media screen and (max-width: $break_w500){
        @content;
    }
}

@mixin w400($name, $value){
    @media screen and (max-width: $break_w400){
        #{$name}: $value;
    }
}

@mixin w400-block(){
    @media screen and (max-width: $break_w400){
        @content;
    }
}

@mixin from($min_width){
    @media screen and (min-width: $min_width){
        @content;
    }
}

@mixin to($max_width){
    @media screen and (max-width: $max_width){
        @content;
    }
}

@mixin from-to($min_width, $max_width){
    @media screen and (min-width: $min_width) and (max-width: $max_width){
        @content;
    }
}

@mixin u-row-flex($offset){
    display: flex;
    flex-wrap: wrap;
    margin-left: math.div($offset, -2);
    margin-right: math.div($offset, -2);
}

@mixin u-col($offset){
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: ($offset * 0.5);
    margin-right: ($offset * 0.5);
}

@mixin u-size($n, $columns, $offset){
    width: #{calc(100% / #{$columns} * #{$n} - #{$offset})};
}

@mixin reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

@mixin debug($background, $outline){
    height: 100vh;
    position: fixed;
    width: 100%;

    > div{
        height: inherit;
        @include wrapper();

        > div{
            height: inherit;
            @include row-flex();

            > div{
                @include col();
                @include size(1);
                height: inherit;
                outline: $outline;
                background: $background;
            }
        }
    }
}

@mixin clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}